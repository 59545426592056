<template>
  <el-table
    v-loading="isLoading"
    :data="items"
    style="width: 100%"
    :border="true"
    highlight-current-row
  >
    <el-table-column prop="admin_name" label="Admin name" />
    <el-table-column prop="collection" label="Collection" />
    <el-table-column prop="date" label="Date" />
    <el-table-column prop="type" label="Action" />
    <el-table-column prop="item_id" label="View" width="75" align="center">
      <template #default="scope">
        <div class="cursor-pointer text-hover" @click="view(scope.row)">
          <i class="bi bi-eye-fill fs-1"></i>
        </div>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="showModal" destroy-on-close width="80%">
    <news-item v-if="activeCollection === 'news'" :item="activeItem" />
    <library-item v-if="activeCollection === 'library'" :item="activeItem" />

    <template #footer>
      <button class="btn-grey fs-16" @click="showModal = false">Close</button>
    </template>
  </el-dialog>
</template>

<script>
// Add more info on action
import NewsItem from '@/components/items/News';
import LibraryItem from '@/components/items/Library';

export default {
  name: 'ActivityItem',
  props: ['items', 'isLoading'],
  components: {
    NewsItem,
    LibraryItem,
  },
  data() {
    return {
      showModal: false,
      activeCollection: '',
      activeItem: {},
    };
  },
  methods: {
    view(action) {
      this.activeCollection = action.collection.toLowerCase();
      this.activeItem = action.item;
      this.showModal = true;
    },
  },
};
</script>
