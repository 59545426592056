<template>
  <div class="row library-item">
    <div class="col-12">
      <el-table :data="[item]" style="width: 100%" :border="true">
        <el-table-column prop="title" label="Dataset">
          <template #default="scope">
            <div class="">
              <p class="text-primary text-bold break-word">
                {{ scope.row.title }}
              </p>
              <p class="break-word">{{ scope.row.description }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="category" label="Data category" width="120">
          <template #default="scope">
            {{ getLabel(scope.row.category) }}
          </template>
        </el-table-column>
        <el-table-column prop="technology" label="Technology" width="120">
          <template #default="scope">
            {{ capitalize(scope.row.technology) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="database"
          label="Database/Company"
          width="150"
          align="center"
        >
          <template #default="scope">
            <a :href="scope.row.link" target="_blank" class="inline-link">{{
              capitalize(scope.row.database)
            }}</a>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import data_categories from '@/utils/options/data_categories';

export default {
  name: 'LibraryItem',
  props: ['item'],
  data() {
    return {
      categories: data_categories,
    };
  },
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    view(link) {
      return window.open(link, '_blank');
    },
    getLabel(value) {
      return this.categories.find((item) => item.value === value).label;
    },
  },
};
</script>

<style lang="scss">
.library-item {
  margin: 0 auto;
  table {
    max-width: 80% !important;
  }
}
.el-table__cell:last-child {
  .cell {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
