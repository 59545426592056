<template>
  <!-- News Item -->
  <div class="row news-item">
    <div class="col-12 col-lg-7 text-col">
      <h4>{{ item.title }}</h4>
      <p class="text-small mb-4">{{ item.date }}</p>

      <p>{{ item.description }}</p>
      <div class="mt-2 mb-4">
        <a :href="item.link" target="_blank" rel="noopener noreferrer">
          <button class="btn-sm-accent">Read more</button>
        </a>
      </div>

      <div class="tags keywords-tags">
        <el-tag v-for="word in item.tags"> {{ word }}</el-tag>
      </div>
      <div class="tags location-tags">
        <el-tag>{{ item.location }}</el-tag>
      </div>
    </div>
    <div class="col-12 col-lg-5 img-col">
      <div class="img-container">
        <img
          class="img-fluid preview-img"
          :src="item.image"
          :alt="item.title"
        />
        <div class="overlay cursor-pointer">
          <button class="btn-sm-accent" @click="$emit('replaceImg')">
            Replace image
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsItem',
  props: ['item'],
  emit: ['replaceImg'],
};
</script>

<style lang="scss">
.img-container {
  position: relative;
  width: 100%;
  .img-fluid {
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }
  .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  &:hover {
    .img-fluid {
      opacity: 0.3;
    }
    .overlay {
      opacity: 1;
    }
  }
}
</style>
