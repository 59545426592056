<template>
  <div class="container mt-4">
    <div class="row mt-4 mb-4">
      <el-tabs v-model="activeTab" class="tabs" @tab-click="handleTabChange">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.value"
          :label="tab.label"
          :name="tab.value"
        />
      </el-tabs>
    </div>
    <div class="row mb-4">
      <div class="col-12 btn-right">
        <el-pagination
          background
          layout="prev, pager, next"
          v-model:currentPage="page"
          :total="total"
          :page-size="pageSize"
          @current-change="handlePageChange"
        />
      </div>
    </div>
    <activity-item :items="items" :isLoading="isLoading" />
  </div>
</template>

<script>
import ActivityItem from '@/components/items/Activity';
import Database from '@/utils/services/Database';

export default {
  name: 'AdminActivity',
  components: {
    ActivityItem,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      activeTab: 'news',
      page: 1,
      total: 1,
      pageSize: 20,
      tabs: [
        {
          label: 'News',
          value: 'news',
        },
        {
          label: 'Library',
          value: 'library',
        },
      ],
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const { data, total } = await Database.getActivity({ 
        type: 'all_activities',
        collection: this.activeTab,
        limit: this.pageSize,
        offset: this.page * this.pageSize - this.pageSize,
      });
      
      this.items = data;
      this.total = total || 1;
      this.isLoading = false;
    },
    async handleTabChange(tab) {
      this.activeTab = tab.paneName;
      await this.getData();
    },
    async handlePageChange(val) {
      this.page = val;
      await this.getData();
    },
  },
};
</script>
